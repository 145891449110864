.hero-banner {
    background: rgb(2,0,36);
    background: linear-gradient(36deg, rgba(2,0,36,1) 0%, rgba(5,32,34,1) 35%, rgba(29,91,97,1) 100%);
    margin:0;
    border:0;
    margin-top:0px;
}

.nav {
    max-width:1200px;
    margin:0 auto;
    padding:20px 0;
}

.nav .brand {
    max-width:100px;
    font-size:30px;
}

.hero-logo {
    max-width:350px;
    margin:50px 0;
    font-family: "Poetsen One", sans-serif;
    font-weight: 600;
    font-style: normal;
}

h1, h2, h3, h4, h5 {
    font-family: "Poetsen One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.hero-banner h1 {
    color:#10D5E3;
    margin:25px 0;
    font-weight:200;
    font-size:68px;
    padding:0;
}

.hero-banner .hero-banner-content {
    padding:50px 0;
}

.hero-banner h1 span {
    color:#E36610;
}

.hero-banner .lead {
    max-width:640px;
    text-align:left;
}

.hero-banner .lead h3 {
    font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color:rgba(255,255,255,0.8);
  font-size:25px;
  line-height:35px;
}

.hero-banner img {
    max-width:100%;
}

.hero-banner .row {
    max-width:1200px;
}

.splitter {
    width:100%;
    height:75px;
    background:url("../images/splitter.png") bottom center;
    background-position:center center;
    background-size:cover;
    margin:0;
    padding:0;
}